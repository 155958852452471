/** Material-ui components */
import ListItem from '@mui/material/ListItem'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import EmailIcon from '@mui/icons-material/Email'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import { styled } from '@mui/material/styles'
import { red } from '@mui/material/colors'

function CourseMemberNotFoundCard({ id, email, label, isButton, buttonType, clickHandler }) {
  return (
    <MuiListItem divider id={id} key={id}>
      <MuiAvatar alt={label} verified={false} />

      <Stack
        direction="column"
        spacing={1}
        sx={{ justifyContent: 'space-around', padding: '0.25rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <EmailIcon sx={{ marginRight: '0.25rem' }} />
          <Typography
            id={label}
            sx={{
              fontSize: '1rem',
              textTransform: 'lowercase'
            }}>
            {email}
          </Typography>
        </Box>
        <Chip
          label={label}
          sx={{
            width: '8rem',
            backgroundColor: '#E0F7FA'
          }}
        />
      </Stack>
      {isButton && buttonType === 'add' && (
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
          <MuiAddButton variant="contained" onClick={() => clickHandler(id)}>
            <AddCircleOutlineIcon fontSize="medium" sx={{ marginRight: '0.25rem' }} />
            Add
          </MuiAddButton>
        </Box>
      )}
      {isButton && buttonType === 'remove' && (
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
          <MuiRemoveButton variant="outlined" color="secondary" onClick={() => clickHandler(id)}>
            <CancelIcon fontSize="medium" sx={{ marginRight: '0.25rem' }} />
            Remove
          </MuiRemoveButton>
        </Box>
      )}
    </MuiListItem>
  )
}

export default CourseMemberNotFoundCard

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  justifyContent: 'flex-start'
}))

const MuiAvatar = styled(Avatar)<{ verified?: boolean }>(({ theme, verified }) => ({
  border: `2.5px solid`,
  // borderColor: verified ? theme.palette.primary.main : theme.palette.grey[300],
  margin: '0 1rem 0 0.25rem',
  width: '3.5rem',
  height: '3.5rem'
}))

const MuiAddButton = styled(Button)(({ theme }) => ({
  // borderRadius: '0.25rem'
}))

const MuiRemoveButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: red[200]
  }
}))
