import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import MuiSkeleton from '@mui/material/Skeleton'
import ListSubheader from '@mui/material/ListSubheader'
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'

export const MuiPaperOuter = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.25rem',
  background: '#fff',
  minHeight: '40rem'
}))

export const MuiPaperInner = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '2rem',
  borderRadius: '0.25rem',
  maxHeight: '50rem',
  overflowY: 'auto',
  overflowX: 'hidden'
}))

export const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  width: '100%',
  padding: 0,
  borderRadius: '0.25rem',
  minHeight: '21.5rem',
  backgroundColor: theme.palette.background.gray
}))

export const MuiCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root path': {
    color: `${theme.palette.secondary.main}`
  }
}))

export const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))

export const MuiListItem = styled(ListItem)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    backgroundColor: theme.palette.common.white
  }
}))

export const MuiAddButton = styled(Button)(({ theme }) => ({
  // borderRadius: '0.25rem'
}))

export const MuiTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.background.lightGray,
  color: theme.palette.text.primary,
  minWidth: '18rem',
  width: '100%',
  '& label': {
    color: theme.palette.text.primary
  },
  '& label.Mui-focused': {
    color: theme.palette.secondary.main
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  '& .MuiInputBase-input': {
    padding: '12px'
  }
}))

export const Skeleton = withStyles((theme) => ({
  root: {
    width: '100%'
  }
}))(MuiSkeleton)
