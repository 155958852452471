export const formatDate = (isoDate) => {
  // Parse the ISO date string into a Date object
  const date = new Date(isoDate)

  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: '2-digit'
  })

  return formatter.format(date)
}
