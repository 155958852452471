import Instructor from '../../instructor/instructor'
import List from '@mui/material/List'

function InstructorList({ instructorIds, currentUser }) {
  return (
    <List>
      {instructorIds.map((instructorId, index) => (
        <Instructor userId={instructorId} currentUser={currentUser} />
      ))}
    </List>
  )
}

export default InstructorList
