import { useEffect, useReducer, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { getUserMembersByOrgId } from '../../../../redux/thunks/organizations/getUserMembersByOrgId'

const useLoadMembers = ({
  organizationId,
  initialLimit = 10,
  enabled,
  initialPage = 1,
  role,
  callback
}) => {
  const dispatch = useDispatch()
  const abortControllerRef = useRef(null)

  // TODO: check pagination when user click in next page for several times
  const initState = {
    memebers: [],
    pagination: {
      currentPage: initialPage,
      totalRecords: 0,
      perPage: initialLimit
    },
    /** type RequestStatus = "idle" | "pending" | "fulfilled" | "rejected" */
    requestStatus: 'idle'
  }
  const [state, updateEvent] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initState)

  useEffect(() => {
    if (!enabled) return

    callback?.()
    updateEvent({
      requestStatus: 'pending',
      members: []
    })
    abortControllerRef.current = new AbortController()

    dispatch(
      getUserMembersByOrgId({
        orgId: organizationId,
        limit: state.pagination.perPage,
        page: state.pagination.currentPage,
        role,
        signal: abortControllerRef.current.signal
      })
    ).then((result) => {
      if (result.error)
        return updateEvent({
          requestStatus: 'rejected',
          members: initState.members,
          pagination: initState.pagination
        })
      const { members, pagination } = result?.data

      return updateEvent({
        requestStatus: 'fulfilled',
        members,
        pagination
      })
    })

    return () => {
      updateEvent({ members: [] })
      abortControllerRef.current?.abort()
    }
  }, [
    enabled,
    dispatch,
    organizationId,
    state.pagination.currentPage,
    state.pagination.perPage,
    role
  ])

  return [state, updateEvent]
}

export default useLoadMembers
