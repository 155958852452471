import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { searchUsersByEmails } from '../../../../../../redux/thunks/search/searchUsersByEmails'
import { TextField, Chip, Box, Typography, Button, List, ListItem, Stack } from '@mui/material'
import { styled } from '@mui/material'
import { Email } from '@mui/icons-material'
import CourseMemberCard from '../../course-member-card'
import { grey } from '@mui/material/colors'
import SearchIcon from '@mui/icons-material/Search'
import HelpIcon from '@mui/icons-material/Help'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'

const CourseAddMultipleMembers = ({ users, setUsers, notFound, setNotFound }) => {
  const [emails, setEmails] = useState('')
  const dispatch = useDispatch()

  const handleEmailsChange = (emails) => {
    console.log('Emails:', emails)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    // Handle form submission
    dispatch(searchUsersByEmails({ emails: emails })).then((response) => {
      if (response.code === 200) {
        setUsers(response.profiles)
        setNotFound(response.usersNotFound)
        setEmails('')
      }
    })
  }

  return (
    <MuiBox component="form" onSubmit={handleSubmit}>
      <EmailInput onEmailsChange={handleEmailsChange} emails={emails} setEmails={setEmails} />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          sx={{ margin: '1rem 0 1.25rem 0' }}
          disabled={!emails}>
          Search for {emails.length} CivilTalk Members
        </Button>
      </Box>
    </MuiBox>
  )
}

export default CourseAddMultipleMembers

const EmailInput = ({ onEmailsChange, emails, setEmails, notFount, setNotFound }) => {
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState('')
  const [invalidEmails, setInvalidEmails] = useState('')

  const validateEmail = (email) => {
    if (email.length > 254) return false

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault()
      const email = inputValue.trim().toLowerCase()
      if (validateEmail(email)) {
        setEmails([...emails, email])
        onEmailsChange([...emails, email])
        setError('')
        setInputValue('')
      } else {
        setError('You entered one or more invalid email addresses')
      }
    }
  }

  const handleDelete = (emailToDelete) => () => {
    const newEmails = emails.filter((email) => email !== emailToDelete)
    setEmails(newEmails)
    onEmailsChange(newEmails)
  }

  const handleChange = (event) => {
    setInputValue(event.target.value)
    setError('')
    const emailParts = event.target.value.split(/[, ]+/)
    if (emailParts.length > 1) {
      addEmails(event.target.value)
    }
  }

  const addEmails = (value) => {
    const emailParts = value
      .split(/[, ]+/)
      .map((email) => email.trim().toLowerCase())
      .filter((email) => email !== '')
    const validEmails = emailParts.filter((email) => validateEmail(email))
    const invalidEmailsFound = emailParts.filter((email) => !validateEmail(email))

    console.log('emailParts', emailParts)

    console.error('[ERROR]: invalidEmailsFound:', invalidEmailsFound)

    if (invalidEmailsFound.length > 0) {
      setError('You entered one or more invalid email addresses:')
      setInvalidEmails(invalidEmailsFound.join(', '))
    } else {
      setError('')
    }

    const newEmails = [...emails, ...validEmails.filter((email) => !emails.includes(email))].map(
      (email) => email.toLowerCase()
    )

    const lowercaseEmails = newEmails.map((e) => e.toLowerCase())
    console.log('lowercaseEmails', lowercaseEmails)

    setEmails(newEmails)
    onEmailsChange(newEmails)
    setInputValue('')
  }

  return (
    <MuiEmailInputContainer>
      <MuiHeaderBox>
        <Typography variant="h5" sx={{ fontSize: '1.25rem', fontWeight: '500', padding: '0.5rem' }}>
          Enter Course Member Emails
        </Typography>
      </MuiHeaderBox>
      <Stack direction="row" sx={{ margin: '1rem 1rem 0.5rem 1rem', alignItems: 'center' }}>
        <HelpIcon sx={{ mr: 1 }} />
        <Typography variant="body2" sx={{ fontSize: '1.25rem', fontWeight: '400' }}>
          Instructions
        </Typography>
      </Stack>
      <List sx={{ mb: 1 }}>
        <ListItem sx={{ fontSize: '1rem', ml: 1 }}>
          <LightbulbOutlinedIcon />
          To bulk add emails, copy and paste a list of emails to search for multiple CivilTalk
          users.
        </ListItem>
        <ListItem sx={{ fontSize: '1rem', ml: 1 }}>
          <LightbulbOutlinedIcon />
          To add emails individually, add the email and
          <span style={{ marginLeft: '0.25rem', fontWeight: 700 }}>press enter.</span>
        </ListItem>
      </List>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-around',
          width: '95%',
          padding: '0 0.5rem'
        }}>
        <MuiTextField
          variant="outlined"
          label="Enter email addresses..."
          placeholder="Enter email addresses..."
          fullWidth
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          error={!!error}
          helperText={error + ' ' + invalidEmails}
        />
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, ml: 2, mr: 1, mb: 1 }}>
        {emails &&
          emails.map((email, index) => (
            <MuiChip
              key={index}
              icon={<Email />}
              label={email}
              onDelete={handleDelete(email)}
              color="primary"
              variant="outlined"
            />
          ))}
      </Box>
    </MuiEmailInputContainer>
  )
}

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //
const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  // gap: '1rem',
  margin: '1rem 0',
  borderRadius: '0.25rem',
  background: '#fff',
  border: `1px solid ${theme.palette.secondary.main}`
}))

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

/** Email Input Styles */

const MuiEmailInputContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
  // gap: '1rem'
}))

const MuiHeaderBox = styled(Box)(({ theme }) => ({
  // display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.secondary.light,
  padding: '0.5rem 0'
}))

const MuiTextField = styled(TextField)(({ theme }) => ({
  // background: theme.palette.background.gray,
  color: theme.palette.text.primary,
  // minWidth: '18rem',
  // width: '90%',
  // padding: '0 0.5rem',
  // minWidth:
  position: 'relative',
  '& label': {
    color: theme.palette.text.primary
  },
  '& label.Mui-focused': {
    color: theme.palette.secondary.main
  },
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#B2BAC2'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: '#E0E3E7'
    },
    '&:hover fieldset': {
      // borderColor: '#B2BAC2'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  '& .MuiInputBase-input': {
    backgroundColor: grey[200]
  },
  '& .MuiFormHelperText-root': {
    color: theme.palette.text.primary,
    fontWeight: '500',
    padding: '0.15rem'
  },
  // target the error text
  '& .Mui-error': {
    color: theme.palette.error.main,
    fontSize: '1rem'
  }
}))

const MuiChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  borderColor: theme.palette.secondary.light,
  fontSize: '1rem',
  textTransform: 'lowercase',
  color: theme.palette.text.primary,
  '& .MuiChip-deleteIcon': {
    color: theme.palette.text.primary
  }
}))
