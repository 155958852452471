import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getMyCourses } from '../../../../redux/thunks/courses/getMyCourses'
import { getCoursesByOrgID } from '../../../../redux/thunks/courses/getCoursesByOrgID'

const useLoadCourses = (
  currentProfile,
  currentUser,
  mode,
  organizationId,
  isAdmin,
  isInstructor
) => {
  const [openCourses, setOpenCourses] = useState([])
  const [previousCourses, setPreviousCourses] = useState([])
  const [pendingCourses, setPendingCourses] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  async function getOrgCourses(organizationId) {
    const result = await dispatch(getCoursesByOrgID({ organizationId }))

    return result
  }

  async function _getMyCourses(userId) {
    const result = await dispatch(getMyCourses({ userId }))

    return result
  }

  useEffect(() => {
    if (mode === 'report' && isAdmin) {
      // org admin view
      console.log('[DEBUG], fetching org courses for admin')
      const fetchCourses = async () => {
        try {
          const { courses } = await getOrgCourses(organizationId)

          setOpenCourses(courses.filter((course) => course.status === 'open'))
          setPreviousCourses(courses.filter((course) => course.status === 'closed'))
          setPendingCourses(courses.filter((course) => course.status === 'pending'))
        } catch (error) {
          console.error('[ERROR]: caught while fetching org courses for admin:', error)
        }
      }

      fetchCourses()
    } else if (mode === 'report' && isInstructor) {
      // org instructor view
      console.log('[DEBUG], fetching org courses for instructor')
      const fetchCourses = async () => {
        try {
          const { courses } = await getOrgCourses(organizationId)

          const instructorCourses = courses.filter((course) =>
            course.instructorIds.includes(currentUser.id)
          )

          console.log('[DEBUG]: Courses where user is instructor:', instructorCourses)

          setOpenCourses(instructorCourses.filter((course) => course.status === 'open'))
          setPreviousCourses(instructorCourses.filter((course) => course.status === 'closed'))
          setPendingCourses(instructorCourses.filter((course) => course.status === 'pending'))
        } catch (error) {
          console.error('[ERROR]: caught while fetching org courses for instructor:', error)
        }
      }

      fetchCourses()
    } else {
      // my-courses page view
      // show all courses where user is member or user is instructor
      console.log('[DEBUG], fetching my courses')
      const fetchCourses = async () => {
        try {
          const { courses } = await _getMyCourses(currentProfile.userId)

          setOpenCourses(courses.filter((course) => course.status === 'open'))
          setPreviousCourses(courses.filter((course) => course.status === 'closed'))
          setPendingCourses(courses.filter((course) => course.status === 'pending'))
        } catch (error) {
          console.error('[ERROR]: caught while fetching my courses:', error)
        }
      }

      fetchCourses()
    }

    setIsLoading(false)
  }, [])

  return { openCourses, previousCourses, pendingCourses, isLoading }
}

export default useLoadCourses
