import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import config from '../../../config'
import { logout } from '../users/logout.js'

export const getCourseInvitesByCourseId = (params) => {
  let responseCode = ''
  const method = 'GET'
  const url = `${config.baseURL}/api/course-invites/${params.courseId}`
  const headers = {
    'Content-Type': 'application/json'
  }

  return (dispatch) => {
    console.log('[FETCH]: Sending get course invites by course id request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        responseCode = response.status

        // TODO: Mike F. - should we put this in all thunks or put it directly in the authenticatedFetch function?
        if (responseCode === 401) {
          dispatch(logout())

          return {
            success: false,
            code: responseCode,
            message: 'Unauthorized'
          }
        }

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          console.log(`[SUCCESS] THUNK:`, data)
          return {
            error: false,
            code: responseCode,
            message: data.message,
            courseInvites: data.courseInvites
          }
        } else {
          console.error(`[ERROR]: while getting course invites by course id`, data)

          return {
            error: true,
            code: responseCode,
            message: data.message || 'Error getting course invites by course id',
            errors: data || {}
          }
        }
      })
  }
}
