import * as Sentry from '@sentry/react'
import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const createCourseInvites = (params) => {
  let responseCode = ''
  const method = 'POST'
  const url = `${config.baseURL}/api/course-invites/`
  const headers = {
    'Content-Type': 'application/json'
  }

  const requestBody = {
    emails: params.emails,
    courseId: params.courseId
  }

  console.log('[DEBUG]: createCourseInvites params', params)
  console.log('[DEBUG]: createCourseInvites requestBody', requestBody)

  const requestBodyJSON = JSON.stringify(requestBody)

  return (dispatch) => {
    console.log('[FETCH]: Sending create course invite request to CivilTalk')

    // dispatch(loaderActions.startLoader())

    return authenticatedFetch(url, {
      method,
      body: requestBodyJSON,
      credentials: 'include',
      headers
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 201) {
          console.log(`[SUCCESS]:`, data.message)
          return {
            error: false,
            code: responseCode,
            message: data.message,
            courseInviteIds: data.insertedIds
          }
        } else {
          console.error(`[ERROR]: while creating course invites`, data)

          // Log error to Sentry
          Sentry.captureException(data)

          return {
            error: true,
            code: responseCode,
            message: data.message || 'Error creating course invites',
            errors: data || {}
          }
        }
      })
      .catch((error) => {
        console.error('[ERROR]: while creating course invite', error)

        // Log error to Sentry
        Sentry.captureException(error)

        return {
          error: true,
          code: responseCode,
          message: error.message || 'Error creating course invites',
          errors: error || {}
        }
      })
  }
}
