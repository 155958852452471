import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { searchOrganizationMembersByName } from '../../../../redux/thunks/organizations/searchOrganizationMembersByName'

const useSearchMembersByName = ({ organizationId, name, updateEvent }) => {
  const dispatch = useDispatch()
  const abortControllerRef = useRef(null)

  useEffect(() => {
    if (!name?.trim()) return

    updateEvent({ requestStatus: 'pending', members: [] })

    abortControllerRef.current = new AbortController()

    dispatch(
      searchOrganizationMembersByName({
        orgId: organizationId,
        name: name?.trim(),
        signal: abortControllerRef.current.signal
      })
    ).then((result) => {
      if (result.error) return updateEvent({ requestStatus: 'rejected' })

      updateEvent({
        members: result.data,
        requestStatus: 'fulfilled',
        pagination: { perPage: 10, currentPage: 1, totalRecords: result.data.length }
      })
    })

    return () => {
      updateEvent({ members: [] })
      abortControllerRef.current?.abort()
    }
  }, [dispatch, organizationId, name])

  return
}

export default useSearchMembersByName
