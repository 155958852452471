import { useState } from 'react'
import CourseAddMultipleMembers from './course-add-multiple-members'
import AddSelectedCourseMembers from './add-selected-course-members'
import SearchResultsList from './search-results-list'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { styled } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

function AddCourseMembersDialog({
  open,
  handleCloseDialog,
  users,
  setUsers,
  notFound,
  setNotFound,
  handleAddUsersToMembers,
  handleAddMembersToInvite
}) {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <MuiDialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}
        onClick={handleCloseDialog}>
        <Button color="inherit" variant="outlined" endIcon={<CancelIcon />}>
          Close
        </Button>
      </Box>
      <CourseAddMultipleMembers
        users={users}
        setUsers={setUsers}
        notFound={notFound}
        setNotFound={setNotFound}
      />
      <AddSelectedCourseMembers
        users={users}
        notFound={notFound}
        handleAddUsersToMembers={handleAddUsersToMembers}
        handleCloseDialog={handleCloseDialog}
        handleAddMembersToInvite={handleAddMembersToInvite}
      />
      <SearchResultsList
        users={users}
        setUsers={setUsers}
        notFound={notFound}
        setNotFound={setNotFound}
      />
    </MuiDialog>
  )
}

export default AddCourseMembersDialog

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    // maxWidth: '400px',
    padding: '1rem',
    borderRadius: '0.25rem'
  }
}))
