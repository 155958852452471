import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'
import queryString from 'query-string'

export const updateUserMemberRolesByOrgId = ({ organizationId, userId, roles }) => {
  let responseCode = ''
  const method = 'PATCH'
  let url = `${config.baseURL}/api/memberships/update-roles`

  const headers = {
    'Content-Type': 'application/json'
  }

  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return (dispatch) => {
    // Return the fetch so react components calling 'store.dispatch()' can use 'then()'
    return authenticatedFetch(url, {
      method,
      headers,
      credentials: 'include',
      body: JSON.stringify({ organizationId, userId, roles })
    })
      .then((response) => {
        responseCode = response.status

        return response.json()
      })

      .then((data) => {
        if (responseCode === 200) {
          return {
            error: false,
            code: responseCode,
            message: data?.message,
            data: data?.data
          }
        } else {
          return {
            error: true,
            code: responseCode,
            message: data?.message
          }
        }
      })
      .catch((error) => {
        return {
          error: true,
          code: '',
          message: error
        }
      })
  }
}
