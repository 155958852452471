import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import getAvatar from '../../../../../utils/functions/getAvatar'
import getName from '../../../../../utils/functions/getName'
import followUser from './functions/follow-user'
import unfollowUser from './functions/unfollow-user'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'

import {
  OuterContainer,
  InnerContainer,
  IconContainer,
  IconSpan,
  ImageContainer,
  NameContainer,
  Name
} from './styled/profile-comps'
import { CircularProgress } from '@mui/material'

function Profile({ profile, isFollowing, isInstructor }) {
  const { userId, documentVerified, avatar } = profile
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const [processing, setProcessing] = useState(false)
  const displayFollowActions = true
  const isCurrentUser = profile.userId === currentUser.id

  const handleFollowUser = (e, profile) => {
    e.stopPropagation()

    followUser(profile, setProcessing, dispatch)
  }

  const handleUnfollowUser = (e, profile) => {
    e.stopPropagation()

    unfollowUser(profile, currentUser, setProcessing, dispatch)
  }

  return (
    <ListItem
      key={userId}
      sx={{ pt: 2, pb: 2, pl: 0, pr: 0, backgroundColor: 'common.white' }}
      divider>
      <ListItemAvatar>
        <ImageContainer
          verified={documentVerified}
          style={{ backgroundImage: `url(${getAvatar(avatar)})` }}
          onClick={() => history.push(`/profile/${userId}`)}>
          {!isFollowing && displayFollowActions && !isCurrentUser && (
            <IconContainer onClick={(e) => handleFollowUser(e, profile)}>
              {processing ? (
                <CircularProgress
                  size={'1rem'}
                  sx={{
                    display: 'block',
                    color: 'common.white',
                    position: 'absolute',
                    top: '25%',
                    left: '25%'
                  }}
                />
              ) : (
                <IconSpan className="material-icons">person_add</IconSpan>
              )}
            </IconContainer>
          )}

          {isFollowing && displayFollowActions && !isCurrentUser && (
            <IconContainer onClick={(e) => handleUnfollowUser(e, profile)}>
              {processing ? (
                <CircularProgress
                  size={'1rem'}
                  sx={{
                    display: 'block',
                    color: 'common.white',
                    position: 'absolute',
                    top: '25%',
                    left: '25%'
                  }}
                />
              ) : (
                <IconSpan className="material-icons">person_remove</IconSpan>
              )}
            </IconContainer>
          )}
        </ImageContainer>
      </ListItemAvatar>
      <Box>
        <ListItemText
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
            justifyContent: 'space-between',
            margin: 0,
            padding: '1rem 0 0.5rem 0.5rem',
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
            }
          }}
          primary={getName(profile)}
          primaryTypographyProps={{}}
          onClick={() => history.push(`/profile/${userId}`)}
        />
        {isInstructor && (
          <Chip
            label={'Instructor'}
            color="secondary"
            sx={{
              width: '6rem'
            }}
          />
        )}
      </Box>
    </ListItem>
  )
}

export default Profile
