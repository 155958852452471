import { useCallback, useEffect, useState } from 'react'
import { MuiTextField } from '../styled/mui-styled'
import debounce from 'lodash/debounce'

const SearchByUserInput = ({ searchByUserName, setSearchByUserName }) => {
  const handleSearch = useCallback(
    debounce((event) => {
      setSearchByUserName(event.target.value)
    }, 500),
    []
  )

  return (
    <MuiTextField
      placeholder="Search Members"
      defaultValue={searchByUserName}
      onChange={handleSearch}
    />
  )
}

export default SearchByUserInput
