import CourseInstructorCard from './course-instructor-card'
import { styled } from '@mui/material'
import { Box, List, ListSubheader } from '@mui/material'

function CourseInstructorsList({ instructors, handleRemoveInstructor }) {
  return (
    <MuiList>
      <MuiListSubheader key={'subheader-key'}>Course Instructors</MuiListSubheader>
      {instructors.map((instructor) => (
        <CourseInstructorCard
          id={instructor._id}
          key={instructor._id}
          instructor={instructor}
          label="Instructor"
          isButton={true}
          buttonType={'remove'}
          clickHandler={handleRemoveInstructor}
        />
      ))}
    </MuiList>
  )
}

export default CourseInstructorsList

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  minWidth: '18rem',
  width: '100%',
  padding: 0,
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.background.gray
}))

const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))
