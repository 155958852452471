import CourseMemberCard from '../../course-member-card'
import CourseMemberNotFoundCard from './course-member-not-found-card'
import { styled } from '@mui/material'
import { Box, List, ListSubheader } from '@mui/material'

function CourseMembersList({ members, handleRemoveMember, notFound, setNotFound }) {
  const handleRemoveNotFoundSearchResult = (email) => {
    console.log('Removing:', email)
    const updatedNotFound = notFound.filter((notFoundEmail) => notFoundEmail !== email)

    setNotFound(updatedNotFound)
  }

  return (
    <MuiList>
      <MuiListSubheader key={'subheader-key'}>Course Members</MuiListSubheader>
      {members.map((member) => (
        <CourseMemberCard
          id={member._id}
          key={member._id}
          member={member}
          label="Member"
          isButton={true}
          buttonType={'remove'}
          clickHandler={handleRemoveMember}
        />
      ))}
      {notFound.map((email) => (
        <CourseMemberNotFoundCard
          id={email}
          key={email}
          email={email}
          label={'Pending Invite'}
          isButton={true}
          buttonType={'remove'}
          clickHandler={handleRemoveNotFoundSearchResult}
        />
      ))}
    </MuiList>
  )
}

export default CourseMembersList

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  minWidth: '18rem',
  width: '100%',
  padding: 0,
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.background.gray
}))

const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))
