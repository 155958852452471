import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileByUserID } from '../../../../../redux/thunks/profiles/getProfileByUserID'
import some from 'lodash/some'
import Profile from '../profile/profile'
import FormError from '../../../../alerts/form-error'

function Instructor({ userId, currentUser }) {
  const [profile, setProfile] = useState({})
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const follows = useSelector(({ follows }) => follows)

  useEffect(() => {
    if (userId) {
      dispatch(getProfileByUserID({ userId })).then((data) => {
        if (!data || data.error) {
          console.log(`ERROR when loading instructor component`)

          const errorMessage =
            data && data.error ? data.message : 'ERROR when loading instructor component'
          setError(errorMessage)
        }

        setProfile(data.profile)
      })
    }
  }, [])

  const isFollowing = some(follows, {
    follower: currentUser.id,
    userId: profile.userId
  })

  return (
    <>
      {error ? (
        <FormError message={error} />
      ) : (
        <Profile profile={profile} isFollowing={isFollowing} isInstructor={true} />
      )}
    </>
  )
}

export default Instructor
