import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { history } from '../../../history/history'
import { createCourse } from '../../../redux/thunks/courses/createCourse'
import { updateCourseSetupStepByCourseID } from '../../../redux/thunks/courses/updateCourseSetupStepByCourseID'
import { createCourseInvites } from '../../../redux/thunks/course-invites/createCourseInvites'
import { getProfileByUserID } from '../../../redux/thunks/profiles/getProfileByUserID'
import CourseFormDialog from './components/course-form-dialog'
/** Step 1 */
import CourseBasics from './components/course-basics'
/** Step 2 */
// import CourseInstructor from './components/course-instructor'
import CourseInstructorsPanel from './components/course-instructors/course-instructors-panel'
/** Step 3 */
import CourseMembersPanel from './components/course-members/course-members-panel'
/** Step 4 */
import CourseReviewStep from './components/course-review/course-review-step'
/** Material-ui Components */
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { OuterContainer, Header } from './styled/course-setup-comps'
/** Custom Hooks */
import useCourseFieldsState from './hooks/use-course-fields-state'
import useLoadCourseFieldsByCourseId from './hooks/use-load-course-fields'
import { update } from 'lodash'

function CourseSetup() {
  const urlParamsObject = useParams()

  /** Stepper State */
  const steps = ['Course Basics', 'Course Instructor', 'Course Members', 'Review Course']
  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const currentProfile = useSelector(({ currentProfile }) => currentProfile)

  /** Form Input State */
  const [courseFields, setCourseFields] = useCourseFieldsState({
    courseId: '',
    subject: '',
    title: '',
    courseNumber: '',
    season: '',
    year: '',
    desc: '',
    instructor: {},
    instructors: [],
    members: [],
    membersToInvite: [],
    courseAssistants: [],
    status: 'pending',
    orgId:
      import.meta.env.VITE_NODE_ENV === 'production'
        ? '65a587332e5f8e0035efe4a4'
        : '658081a4ce604400356103ba'
  })
  const [stepUpdated, setStepUpdated] = useState(false)

  /** Load existing course for editing a course */
  useLoadCourseFieldsByCourseId(urlParamsObject, courseFields, setCourseFields, setLoading)

  const handleSetCourseFields = (newFields) => {
    const updatedFields = {
      ...courseFields,
      ...newFields
    }

    setCourseFields(updatedFields)
  }

  const handleErrors = (errors) => {
    Object.keys(errors).forEach((key) => {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: errors[key] }))
    })
    console.error('[ERROR]: New errors at handleErrors', errors)
  }

  const handleCreateCourse = (courseFields) => {
    return new Promise((resolve, reject) => {
      dispatch(createCourse(courseFields))
        .then((response) => {
          if (response.error) {
            console.error('[ERROR]: Error in handleCreateCourse', response)

            const { errors } = response
            handleErrors(errors)
            reject(errors)
          } else {
            console.log('[SUCCESS]: Success at handleCreateCourse:', response)
            const courseResponse = response

            // SAVE FOR LATER: If the user is the instructor or a member of the course, we need to update their profile.courses

            resolve(courseResponse)
          }
        })
        .catch((error) => {
          console.error('[ERROR]: Error in handleCreateCourse.catch', error)

          reject(error)
        })
    })
  }

  const handleUpdateCourse = (fieldsToUpdate, step, courseId) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateCourseSetupStepByCourseID({
          fieldsToUpdate,
          step,
          courseId
        })
      )
        .then((response) => {
          if (response.error) {
            console.error('[ERROR]: Error in handleUpdateCourse', response)

            handleErrors(response.errors)
            reject(errors)
          } else {
            console.log('[SUCCESS]: Success at handleUpdateCourse:', response)
            const updateCourseResponse = response

            // SAVE FOR LATER: If the user is the instructor or a member of the course, we need to update their profile.courses

            resolve(updateCourseResponse)
          }
        })
        .catch((error) => {
          console.error('[ERROR]: caught in handleUpdateCourse', error)

          reject(error)
        })
    })
  }

  const handleNextStep = async () => {
    // STEP 1:
    if (activeStep === 0) {
      if (urlParamsObject.courseId) {
        // PATCH - Update the course

        if (!stepUpdated) {
          console.log(`*********************************************************`)
          console.log('[DEBUG]: No changes made to courseFields skipping to step', activeStep + 1)
          console.log(`*********************************************************`)
          setActiveStep((activeStep) => activeStep + 1)

          return
        }

        // reset stepUpdated to false for the next step
        setStepUpdated(false)

        const { subject, title, courseNumber, season, year, desc } = courseFields

        const fieldsToUpdate = {
          subject,
          title,
          courseNumber,
          season,
          year,
          desc
        }

        try {
          const updatedCourse = await handleUpdateCourse(
            fieldsToUpdate,
            activeStep,
            urlParamsObject.courseId
          )

          if (updatedCourse.errors) {
            console.error('[ERROR]: step 0 while updating course', updatedCourse.errors)
            handleErrors(updatedCourse.errors)
          } else {
            console.log(`*********************************************************`)
            console.log('[DEBUG]: step 0 updated course basics', updatedCourse)
            console.log(`*********************************************************`)

            setActiveStep((activeStep) => activeStep + 1)
          }
        } catch (error) {
          console.error('[ERROR]: caught while updating course basics:', error)
          handleErrors(error)
        }
      } else {
        const { subject, title, courseNumber, season, year, desc } = courseFields

        // set orgId for different development environments before release to production
        const orgId =
          import.meta.env.VITE_NODE_ENV === 'production'
            ? '65a587332e5f8e0035efe4a4'
            : '658081a4ce604400356103ba'

        const requestBody = {
          subject,
          title,
          courseNumber,
          season,
          year,
          desc,
          instructorId: '',
          instructorIds: [],
          status,
          members: [],
          courseAssistants: [],
          orgId
        }

        const newCourse = await handleCreateCourse(requestBody) // this will be an object

        if (newCourse.errors) {
          console.error('[ERROR]: activeStep === 0 newCourse.errors', newCourse.errors)

          handleErrors(newCourse.errors)
        } else {
          console.log(`*********************************************************`)
          console.log('[DEBUG]: activeStep === 0 and POST', newCourse)
          console.log(`Adding courseId: ${newCourse.course._id} to courseFields`)
          console.log(`*********************************************************`)

          setCourseFields({
            ...courseFields,
            courseId: newCourse.course._id
          })

          setActiveStep((activeStep) => activeStep + 1)
        }
      }
    } else if (activeStep === 1) {
      // STEP 2: PATCH - Update the instructor

      if (!stepUpdated) {
        console.log(`*********************************************************`)
        console.log('[DEBUG]: No changes made to courseFields skipping to step', activeStep + 1)
        console.log(`*********************************************************`)
        setActiveStep((activeStep) => activeStep + 1)

        return
      }

      // reset stepUpdated to false for the next step
      setStepUpdated(false)

      const fieldsToUpdate = {
        instructorIds: courseFields.instructors.map((instructor) => instructor.userId)
      }

      console.log(`****** FieldsToUpdate *******`, fieldsToUpdate)

      try {
        const updatedCourse = await handleUpdateCourse(
          fieldsToUpdate,
          activeStep,
          courseFields.courseId
        )

        if (updatedCourse.errors) {
          console.error('[ERROR]: step 1 while updating course instructor', updatedCourse.errors)
        } else {
          console.log(`*********************************************************`)
          console.log('[DEBUG]: step 1 updated course instructor', updatedCourse)
          console.log(`*********************************************************`)
          setActiveStep((activeStep) => activeStep + 1)
        }
      } catch (error) {
        console.error('[ERROR]: caught while updating instructor:', error)
        handleErrors(error)
      }
    } else if (activeStep === 2) {
      // STEP 3: PATCH - Update the members

      if (!stepUpdated) {
        console.log(`*********************************************************`)
        console.log('[DEBUG]: No changes to courseFields skipping to next step', activeStep + 1)
        console.log(`*********************************************************`)
        setActiveStep((activeStep) => activeStep + 1)

        return
      }

      console.log(`*********************************************************`)
      console.log(
        '[DEBUG]: dispatch(inviteCourseMembers(params.notFoundEmails))',
        courseFields.membersToInvite
      )
      console.log(`*********************************************************`)
      dispatch(
        createCourseInvites({
          emails: courseFields.membersToInvite,
          courseId: courseFields.courseId
        })
      ).then((response) => {
        console.log(`*********************************************************`)
        console.log('[DEBUG]: createCourseInvites response', response)
        console.log(`*********************************************************`)
      })

      // reset stepUpdated to false for the next step
      setStepUpdated(false)

      const fieldsToUpdate = {
        members: courseFields.members.map((member) => member.userId)
      }

      try {
        const updatedCourse = await handleUpdateCourse(
          fieldsToUpdate,
          activeStep,
          courseFields.courseId
        )

        if (updatedCourse.errors) {
          console.error('[ERROR]: step 2 while updating course members', updatedCourse.errors)
          handleErrors(updatedCourse.errors)
        } else {
          console.log(`*********************************************************`)
          console.log('[DEBUG]: step 2 updated course members', updatedCourse)
          console.log(`*********************************************************`)
          setActiveStep((activeStep) => activeStep + 1)
        }
      } catch (error) {
        console.error('[ERROR]: caught while updating members:', error)
        handleErrors(error)
      }

      // const updatedCourse = await handleUpdateCourse(
      //   fieldsToUpdate,
      //   activeStep,
      //   courseFields.courseId
      // )

      // if (updatedCourse.errors) {
      //   console.error('[ERROR]: activeStep === 2 PATCH newCourse.errors', updatedCourse.errors)

      //   handleErrors(updatedCourse.errors)
      // } else {
      //   console.log(`*********************************************************`)
      //   console.log('[DEBUG]: activeStep === 2 and PATCH', updatedCourse)
      //   console.log(`*********************************************************`)

      //   setActiveStep((activeStep) => activeStep + 1)
      // }
    } else {
      // STEP 4: PATCH - Review and finalize the course by setting the status to 'open'
      console.log(`*********************************************************`)
      console.log('[DEBUG]: activeStep === 3 stepUpdated', stepUpdated)
      console.log(`*********************************************************`)

      // if there is no instructor selected we should not proceed
      // set an error message and return
      if (!courseFields.instructors.length) {
        console.error('[ERROR]: No instructor selected', courseFields.instructors)

        handleErrors({
          instructors:
            'Please return to step 2 and select an instructor before opening this course. If you are not ready to add an instructor you can navigate away from this form and return later.  Your work will be saved under the Pending Courses tab.'
        })
        return
      }

      setStepUpdated(false)

      const fieldsToUpdate = {
        status: 'open'
      }

      try {
        const updatedCourse = await handleUpdateCourse(
          fieldsToUpdate,
          activeStep,
          courseFields.courseId
        )

        if (updatedCourse.errors) {
          console.error(
            '[ERROR]: step 3 while updating course at review step',
            updatedCourse.errors
          )

          handleErrors(updatedCourse.errors)
        } else {
          console.log(`*********************************************************`)
          console.log('[DEBUG]: step 3 updated course at review step', updatedCourse)
          console.log(`*********************************************************`)

          setActiveStep((activeStep) => activeStep + 1)

          // Redirect to my-courses page
          history.push('/my-courses?mode=report')
        }
      } catch (error) {
        console.error(
          `[ERROR]: caught while updating courseId: ${courseId} at course review step`,
          error
        )
        handleErrors(error)
      }

      // if (updatedCourse.errors) {
      //   console.error('[ERROR]: activeStep === 3 PATCH newCourse.errors', updatedCourse.errors)

      //   handleErrors(updatedCourse.errors)
      // } else {
      //   console.log(`*********************************************************`)
      //   console.log('[DEBUG]: activeStep === 3 and PATCH', updatedCourse)
      //   console.log(`*********************************************************`)

      //   setActiveStep((activeStep) => activeStep + 1)

      //   // Redirect to my-courses page
      //   history.push('/my-courses?mode=report')
      // }
    }
  }

  const handlePreviousStep = () => {
    setErrors({})

    if (activeStep !== 0) setActiveStep((activeStep) => activeStep - 1)
  }

  return (
    <OuterContainer>
      <Header>Course Setup</Header>
      <Stepper activeStep={activeStep} sx={{ padding: '0.5rem 0' }}>
        {steps.map((label, index) => {
          const stepProps = {}
          const labelProps = {}

          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                sx={{
                  '& .MuiStepLabel-label': { fontSize: '1rem' }
                }}>
                {label}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>

      {loading && <LinearProgress />}
      {activeStep === 0 && (
        <CourseBasics
          courseFields={courseFields}
          handleSetCourseFields={handleSetCourseFields}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          activeStep={activeStep}
          steps={steps}
          setStepUpdated={setStepUpdated}
        />
      )}
      {activeStep === 1 && (
        <CourseInstructorsPanel
          courseFields={courseFields}
          handleSetCourseFields={handleSetCourseFields}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          activeStep={activeStep}
          steps={steps}
          setStepUpdated={setStepUpdated}
        />
      )}
      {activeStep === 2 && (
        <CourseMembersPanel
          courseFields={courseFields}
          handleSetCourseFields={handleSetCourseFields}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          activeStep={activeStep}
          steps={steps}
          setStepUpdated={setStepUpdated}
        />
      )}
      {activeStep === 3 && (
        <CourseReviewStep
          courseFields={courseFields}
          handleSetCourseFields={handleSetCourseFields}
          handleNextStep={handleNextStep}
          handlePreviousStep={handlePreviousStep}
          activeStep={activeStep}
          steps={steps}
          setStepUpdated={setStepUpdated}
        />
      )}
      <CourseFormDialog errors={Object.values(errors)} />
    </OuterContainer>
  )
}

export default CourseSetup
