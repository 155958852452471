import { useState, useEffect } from 'react'
import CourseMemberCard from '../course-member-card'
import CourseStepButtonContainer from '../course-step-button-container'
import getAvatar from '../../../../../utils/functions/getAvatar'
import getName from '../../../../../utils/functions/getName'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  ProfileContainer,
  ProfilePicture,
  ProfileInfoContainer,
  ProfileName
} from '../../styled/course-setup-comps'
import CourseInvites from './components/course-invites'
import Course from '../../../course/course'

function CourseReviewStep({
  courseFields,
  handleSetCourseFields,
  handlePreviousStep,
  handleNextStep,
  activeStep,
  steps,
  setStepUpdated
}) {
  const {
    subject,
    title,
    courseNumber,
    season,
    year,
    desc,
    instructors,
    members,
    courseAssistants,
    status,
    orgId
  } = courseFields

  const [formErrors, setFormErrors] = useState(false)

  useEffect(() => {
    if (courseFields.status === 'pending') {
      setStepUpdated(true)
    }
  }, [courseFields.status, setStepUpdated])

  // if user is on the review step and the course status is pending, set the step to updated so the parent handler can chance the status to open
  // if (courseFields.status === 'pending') {
  //   setStepUpdated(true)
  // }

  return (
    <MuiBox>
      <MuiFlexColumnBox>
        <TableContainer component={Paper} sx={{ minWidth: '18rem', border: '1px solid #333' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'start', backgroundColor: 'secondary.light' }}>
                  <MuiLabel sx={{ fontSize: '1.25rem' }}>Course Details</MuiLabel>
                </TableCell>
                <TableCell sx={{ textAlign: 'start', backgroundColor: 'secondary.light' }}>
                  <MuiLabel></MuiLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(courseFields).map((key) => {
                if (typeof courseFields[key] === 'string') {
                  return (
                    <TableRow key={key}>
                      <TableCell sx={{ textAlign: 'start' }}>
                        <MuiLabel>{key}:</MuiLabel>
                      </TableCell>
                      <TableCell>
                        <MuiLabel>{courseFields[key]}</MuiLabel>
                      </TableCell>
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </MuiFlexColumnBox>
      <MuiFlexColumnBox>
        <MuiList sx={{ minHeight: '7.35rem' }}>
          <MuiListSubheader>Course Instructors</MuiListSubheader>
          {instructors.map((instructor) => (
            <CourseMemberCard key={instructor.userId} member={instructor} label={'Instructor'} />
          ))}
          {/* {instructor._id && <CourseMemberCard member={instructor} label={'Instructor'} />} */}
        </MuiList>
        <MuiList>
          <MuiListSubheader>Course Members</MuiListSubheader>
          {members.map((member) => (
            <CourseMemberCard member={member} key={member._id} label={'Member'} />
          ))}
        </MuiList>
        <CourseInvites courseId={courseFields.courseId} />
      </MuiFlexColumnBox>
      <CourseStepButtonContainer
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
        activeStep={activeStep}
        steps={steps}
        formErrors={formErrors}
        editingCourse={courseFields.status !== 'pending'}
      />
    </MuiBox>
  )
}

export default CourseReviewStep

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  margin: '1rem 0',
  padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff'
}))

const MuiFlexColumnBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  // alignItems: 'flex-start',
  gap: '1rem'
}))

const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  minWidth: '18rem',
  padding: 0,
  borderRadius: '0.25rem',
  minHeight: '21.5rem',
  backgroundColor: theme.palette.background.gray
}))

const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))

const MuiListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    backgroundColor: theme.palette.common.white
  }
}))

const MuiListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.common.white
}))

const MuiLabel = styled(Typography)(({ theme }) => ({
  marginRight: '1rem',
  fontWeight: 500,
  fontSize: '1rem',
  textTransform: 'capitalize'
}))

// member component
const Member = ({ member }) => {
  return (
    <MuiListItem divider>
      <ProfileContainer>
        <ProfilePicture
          id={member._id}
          style={{
            backgroundImage: `url(${getAvatar(member.avatar)})`
          }}
          verified={member.verified}
        />
        <ProfileInfoContainer>
          <Stack direction="column" spacing={1}>
            <Typography
              id={member._id}
              sx={{
                fontSize: '1.05rem',
                fontWeight: 500
              }}>
              {getName(member)}
            </Typography>
            <Typography
              id={member._id}
              sx={{
                fontSize: '0.85rem'
              }}>
              {member.email}
            </Typography>
            <Chip label="Instructor" color="secondary" />
          </Stack>
        </ProfileInfoContainer>
      </ProfileContainer>
    </MuiListItem>
  )
}
