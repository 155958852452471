import { useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getProfilesByUserIDs } from '../../../../../redux/thunks/profiles/getProfilesByUserIDs'
import { styled } from '@mui/material'
import { Box, Typography, Button } from '@mui/material'
import AddCourseInstructorsDialog from './components/add-course-instructors-dialog/add-course-instructors-dialog'
import CourseInstructorsList from './components/course-instructors-list'
import CourseStepButtonContainer from '../course-step-button-container'
import AddIcon from '@mui/icons-material/Add'

function CourseInstructorsPanel({
  courseFields,
  handleSetCourseFields,
  handlePreviousStep,
  handleNextStep,
  activeStep,
  steps,
  setStepUpdated
}) {
  const [instructors, setInstructors] = useState([])
  const [open, setOpen] = useState(false)
  const [formErrors, setFormErrors] = useState(false)
  const dispatch = useDispatch()

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  async function getInstructorProfiles(userIds) {
    try {
      const { profiles } = await dispatch(getProfilesByUserIDs({ userIds: userIds }))

      return profiles
    } catch (error) {
      throw new Error(`[ERROR]: Failed to fetch instructor profile: ${error}`)
    }
  }

  const handleAddInstructors = async (selectedUsers) => {
    setStepUpdated(true)

    let instructors = []

    if (selectedUsers.length) {
      try {
        const instructorIds = selectedUsers.map((user) => user.userId)

        instructors = Object.values(await getInstructorProfiles(instructorIds))
      } catch (error) {
        console.error('[ERROR]: while fetching instructor profile:', error) // Log any errors
      }
    }

    const updatedInstructors = [...instructors]

    setInstructors(updatedInstructors)

    handleSetCourseFields({ ...courseFields, instructors: updatedInstructors })

    handleCloseDialog()
  }

  const handleRemoveInstructor = (id) => {
    setStepUpdated(true)
    const updatedInstructors = instructors.filter((instructor) => instructor._id !== id)

    setInstructors(updatedInstructors)
    handleSetCourseFields({ ...courseFields, instructors: updatedInstructors })
  }

  useEffect(() => {
    setInstructors(courseFields.instructors)
  }, [])

  return (
    <MuiBoxOuterContainer>
      <MuiBoxButtonContainer>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
          Add Instructors
        </Button>
        <AddCourseInstructorsDialog
          open={open}
          handleCloseDialog={handleCloseDialog}
          instructors={instructors}
          handleAddInstructors={handleAddInstructors}
        />
      </MuiBoxButtonContainer>
      <CourseInstructorsList
        instructors={instructors}
        handleRemoveInstructor={handleRemoveInstructor}
      />
      <CourseStepButtonContainer
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
        activeStep={activeStep}
        steps={steps}
        formErrors={formErrors}
        editingCourse={courseFields.status !== 'pending'}
      />
    </MuiBoxOuterContainer>
  )
}

export default CourseInstructorsPanel

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBoxOuterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  margin: '1rem 0',
  padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff'
}))

const MuiBoxButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start'
}))
