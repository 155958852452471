import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import useLoadMembers from '../hooks/use-load-members'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// TABLE Components
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableFooter from '@mui/material/TableFooter'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { Toolbar } from '../styled/organization-admin-comps'
import { formatDate } from '../utils/formatDate'
import {
  MuiPaperOuter,
  MuiPaperInner,
  MuiList,
  MuiCheckbox,
  MuiListSubheader,
  MuiListItem,
  MuiAddButton,
  MuiTextField,
  Skeleton
} from '../styled/mui-styled'
import { RolesTabs } from './roles-tab'
import EditRoleModal from './edit-role-modal'
import { updateUserMemberRolesByOrgId } from '../../../../redux/thunks/organizations/updateUserMemberRolesByOrgId'
import SearchByUserInput from './search-by-user-input'
import useSearchMembersByName from '../hooks/use-search-members-by-name'
import { generateRangeArray } from '../utils/generateRangeArray'

// set orgId for different development environments before release to production
const organizationId =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? '65a587332e5f8e0035efe4a4'
    : '658081a4ce604400356103ba'

function ManageMemberships() {
  // type Role = 'Faculty' | 'Instructor' | '' as 'all'
  const [selectedRole, setSelectedRole] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [visible, setVisible] = useState(false)
  const [searchByUserName, setSearchByUserName] = useState('')

  // state, updateEvent
  const [state, updateEvent] = useLoadMembers({
    organizationId,
    role: selectedRole,
    enabled: !searchByUserName?.trim(),
    callback: () => {
      setSelectedUser(null)
      setVisible(false)
    }
  })

  useSearchMembersByName({ name: searchByUserName, organizationId, updateEvent })

  const dispatch = useDispatch()

  const changeSelectedRole = (value) => setSelectedRole(value)
  const changeSelectedUser = (user) => setSelectedUser(user)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    state.pagination.currentPage > 0
      ? Math.max(
          0,
          (1 + state.pagination.currentPage) * state.pagination.perPage - state?.members?.length
        )
      : 0

  const handleChangePage = (event, newPage) => {
    updateEvent({ pagination: { ...state.pagination, currentPage: newPage + 1 } })
  }

  const handleChangeRowsPerPage = (event) => {
    updateEvent({
      pagination: { ...state.pagination, currentPage: 1, perPage: parseInt(event.target.value, 10) }
    })
  }

  const handleClickOnEditButton = () => setVisible(true)

  const onCancel = () => {
    setVisible(false)
  }

  const onSave = async (roles) => {
    await dispatch(
      updateUserMemberRolesByOrgId({ organizationId, userId: selectedUser.userId, roles })
    ).then((result) => {
      if (result.error) return

      const exisitingMember = state.members?.find((member) => member.userId === result?.data.userId)

      if (exisitingMember) {
        updateEvent({
          members: state.members?.map((member) => {
            if (member.userId === result?.data.userId) {
              return {
                ...member,
                roles: result?.data?.roles,
                whenModified: result?.data?.whenModified
              }
            }
            return member
          })
        })
      }

      return setVisible(false)
    })
  }

  const orgMembers = useMemo(() => {
    const { members, page, rowsPerPage } = state
    return rowsPerPage > 0
      ? members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : members
  }, [state])

  const isLoading = state.requestStatus === 'idle' || state.requestStatus === 'pending'

  return (
    <>
      <EditRoleModal userInfo={selectedUser} onSave={onSave} open={visible} onCancel={onCancel} />
      <MuiPaperOuter>
        <Typography
          variant="h4"
          sx={{
            backgroundColor: 'hsl(131, 25%, 75%)',
            padding: '1rem 2rem',
            borderRadius: '0.25rem 0.25rem 0 0',
            fontSize: '1.5rem',
            fontWeight: '500'
          }}>
          Manage Memberships
        </Typography>

        <MuiPaperInner>
          <div>
            <SearchByUserInput
              searchByUserName={searchByUserName}
              setSearchByUserName={setSearchByUserName}
            />
            <Toolbar>
              <RolesTabs selected={selectedRole} onChange={changeSelectedRole} />

              <Button onClick={handleClickOnEditButton} variant="outlined" disabled={!selectedUser}>
                Edit Role
              </Button>
            </Toolbar>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="members table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>When Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    {generateRangeArray(1, 10).map((row) => (
                      <TableRow
                        key={row}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          <MuiCheckbox color="secondary" disabled />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {orgMembers?.map((row) => (
                      <TableRow
                        key={row?.userId + row?._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          <MuiCheckbox
                            color="secondary"
                            checked={selectedUser?.userId === row?.userId}
                            onChange={() => {
                              const isSameUser = selectedUser?.userId === row?.userId
                              changeSelectedUser(isSameUser ? null : row)
                            }}
                          />
                        </TableCell>
                        <TableCell>{row?.firstName}</TableCell>
                        <TableCell>{row?.lastName}</TableCell>
                        <TableCell>{row?.email}</TableCell>
                        <TableCell>{row?.roles?.join(', ')}</TableCell>
                        <TableCell>{formatDate(row?.whenModified)}</TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
              {!isLoading && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={6}
                      count={state?.pagination?.totalRecords}
                      rowsPerPage={state?.pagination?.perPage}
                      page={state?.pagination?.currentPage - 1}
                      slotProps={{
                        select: {
                          inputProps: {
                            'aria-label': 'rows per page'
                          },
                          native: true
                        }
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </MuiPaperInner>
      </MuiPaperOuter>
    </>
  )
}

export default ManageMemberships
