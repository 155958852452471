import { useMemo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useLoadInstructorProfiles from '../hooks/use-load-instructor-profiles'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

// TABLE Components
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableFooter from '@mui/material/TableFooter'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
// import { formatDate } from '../utils/formatDate'
import {
  MuiPaperOuter,
  MuiPaperInner,
  MuiList,
  MuiListHorizontal,
  MuiListSubheader,
  MuiListItem,
  MuiAddButton,
  MuiTextField,
  Skeleton,
  MuiToolbar,
  MuiCheckbox,
  MuiChip
} from '../styled/mui-styled'
import AddInstructorModal from './add-instructor-modal'
import SearchByUserInput from './search-by-user-input'
import useSearchMembersByName from '../hooks/use-search-members-by-name'
import { generateRangeArray } from '../utils/generateRangeArray'

// set orgId for different development environments before release to production
const organizationId =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? '65a587332e5f8e0035efe4a4'
    : '658081a4ce604400356103ba'

function ManageInstructors({ instructors, handleAddInstructors }) {
  // type Role = 'Faculty' | 'Instructor' | '' as 'all'
  // const [selectedRole, setSelectedRole] = useState('')
  // const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [visible, setVisible] = useState(false)
  const [searchByUserName, setSearchByUserName] = useState('')

  // state, updateEvent
  const [state, updateEvent] = useLoadInstructorProfiles({
    organizationId,
    role: 'Instructor',
    enabled: !searchByUserName?.trim(),
    callback: () => {
      setSelectedUsers([])
      setVisible(false)
    }
  })

  useEffect(() => {
    setSelectedUsers(instructors)
  }, [])

  const isChecked = (id) => {
    return selectedUsers.some((user) => user.userId === id)
  }

  // useSearchMembersByName({ name: searchByUserName, organizationId, updateEvent })

  const dispatch = useDispatch()

  const handleChangeCheckBox = (row) => {
    if (selectedUsers.some((user) => user.userId === row.userId)) {
      // Remove user if already selected
      const filteredUsers = selectedUsers.filter((user) => user.userId !== row.userId)
      setSelectedUsers([...filteredUsers])
    } else {
      // Otherwise, add user
      setSelectedUsers((prevSelected) => [...prevSelected, row])
    }
  }

  const handleDelete = (userId) => {
    const filteredUsers = selectedUsers.filter((user) => user.userId !== userId)

    setSelectedUsers([...filteredUsers])
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    state.pagination.currentPage > 0
      ? Math.max(
          0,
          (1 + state.pagination.currentPage) * state.pagination.perPage - state?.members?.length
        )
      : 0

  const handleChangePage = (event, newPage) => {
    updateEvent({ pagination: { ...state.pagination, currentPage: newPage + 1 } })
  }

  const handleChangeRowsPerPage = (event) => {
    updateEvent({
      pagination: { ...state.pagination, currentPage: 1, perPage: parseInt(event.target.value, 10) }
    })
  }

  const handleClickOnEditButton = () => setVisible(true)

  const onCancel = () => {
    setVisible(false)
  }

  const orgMembers = useMemo(() => {
    const { members, page, rowsPerPage } = state
    return rowsPerPage > 0
      ? members.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : members
  }, [state])

  const isLoading = state.requestStatus === 'idle' || state.requestStatus === 'pending'

  return (
    <>
      <MuiPaperOuter>
        <Typography
          variant="h4"
          sx={{
            backgroundColor: 'hsl(131, 25%, 75%)',
            padding: '1rem 2rem',
            borderRadius: '0.25rem 0.25rem 0 0',
            fontSize: '1.5rem',
            fontWeight: '500'
          }}>
          Add/Edit Course Instructors
        </Typography>

        <MuiPaperInner>
          <Box>
            <MuiToolbar>
              <Typography variant={'h5'}>Instructions:</Typography>
              <Typography sx={{ fontSize: '1rem', margin: '1rem 0' }}>
                This list displays only the profiles currently assigned an Instructor role. If you
                cannot find the desired profile, please navigate to the Manage Roles tab and assign
                the Instructor role to the relevant profile.
              </Typography>
            </MuiToolbar>
          </Box>
          <Box>
            <Typography variant={'h5'}>Selected Instructors:</Typography>
            <Box>
              <MuiListHorizontal>
                {selectedUsers &&
                  selectedUsers.map((user) => (
                    <MuiChip
                      key={user.userId}
                      icon={<AccountCircleIcon />}
                      label={user.fname + ' ' + user.lname}
                      onDelete={() => handleDelete(user.userId)}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
              </MuiListHorizontal>
            </Box>
            <Box>
              <Button variant="contained" onClick={() => handleAddInstructors(selectedUsers)}>
                Save
              </Button>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="members table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>When Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <>
                    {generateRangeArray(1, 5).map((row) => (
                      <TableRow
                        key={row}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          <MuiCheckbox color="secondary" disabled />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation={'pulse'} variant="text" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    {orgMembers?.map((row) => (
                      <TableRow
                        key={row?.userId + row?._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          <MuiCheckbox
                            color="secondary"
                            checked={isChecked(row.userId)}
                            onChange={() => handleChangeCheckBox(row)}
                          />
                        </TableCell>
                        <TableCell>{row?.firstName}</TableCell>
                        <TableCell>{row?.lastName}</TableCell>
                        <TableCell>{row?.email}</TableCell>
                        <TableCell>{row?.roles?.join(', ')}</TableCell>
                        {/* <TableCell>{formatDate(row?.whenModified)}</TableCell> */}
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
              {!isLoading && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={6}
                      count={state?.pagination?.totalRecords}
                      rowsPerPage={state?.pagination?.perPage}
                      page={state?.pagination?.currentPage - 1}
                      slotProps={{
                        select: {
                          inputProps: {
                            'aria-label': 'rows per page'
                          },
                          native: true
                        }
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </MuiPaperInner>
      </MuiPaperOuter>
    </>
  )
}

export default ManageInstructors
