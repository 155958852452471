import { useState } from 'react'
import ManageInstructors from './components/manage-instructors'
import { Dialog, Box, Stack, Typography, Button } from '@mui/material'
import { styled } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

function AddCourseInstructorsDialog({
  open,
  handleCloseDialog,
  instructors,
  handleAddInstructors
}) {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <MuiDialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
      <ManageInstructors instructors={instructors} handleAddInstructors={handleAddInstructors} />
    </MuiDialog>
  )
}

export default AddCourseInstructorsDialog

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    // maxWidth: '400px',
    padding: '1rem',
    borderRadius: '0.25rem'
  }
}))
