import { useEffect, useState } from 'react'
import { getCourseInvitesByCourseId } from '../../../../../../redux/thunks/course-invites/getCourseInvitesByCourseId'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material'
import {
  Box,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge
} from '@mui/material'
import CourseMemberNotFoundCard from '../../course-members/components/course-member-not-found-card'

function CourseInvites({ courseId }) {
  const [invites, setInvites] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchCourseInvites = async () => {
      try {
        const courseInvites = await dispatch(getCourseInvitesByCourseId({ courseId }))

        if (!courseInvites.error) {
          setInvites(courseInvites.courseInvites)
        } else {
          console.error('Error getting course invites by course id', courseInvites)
        }
      } catch (error) {
        console.error('Error getting course invites by course id', error)
      }
    }

    fetchCourseInvites()
  }, [])

  if (!invites.length) return null

  return (
    <MuiList>
      <MuiListSubheader>Course Invites</MuiListSubheader>
      {invites
        .filter((invite) => invite.status === 'pending')
        .map((invite) => (
          <CourseMemberNotFoundCard
            id={invite.email}
            email={invite.email}
            label={invite.status}
            isButton={false}
          />
        ))}
    </MuiList>
  )
}

export default CourseInvites

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  minWidth: '18rem',
  padding: 0,
  borderRadius: '0.25rem',
  minHeight: '21.5rem',
  backgroundColor: theme.palette.background.gray
}))

const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))
