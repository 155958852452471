import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { history } from '../../../../../history/history'
import Instructor from '../instructor/instructor'
// import DetailsSkeleton from './skeletons/details-skeleton'
import CollapsedContent from '../../../../partials/collapsed-content/collapsed-content'
import InstructorList from './components/instructor-list'

import {
  AsideContainer,
  SectionContainer,
  ArticleContainer,
  ArticleHeader,
  TransparentInlineContainer,
  MobileInterestContainer,
  RedArticleHeader,
  WhiteBackgroundContainer,
  Label,
  AboutCopyContainer,
  Digit,
  RatingDigit,
  FollowContent,
  FlexContainer,
  TimesRatedLabel,
  RatingPointsLabel
} from './styled/details-comps'

function Details(props) {
  const { course } = props

  const history = useHistory()
  const currentUser = useSelector((state) => state.currentUser)

  if (!course) {
    return (
      <AsideContainer>
        <DetailsSkeleton />
      </AsideContainer>
    )
  }

  return (
    <AsideContainer>
      <SectionContainer>
        <ArticleContainer>
          {/* <ArticleHeader style={{ marginTop: '0' }}>About:</ArticleHeader> */}
          <ArticleContainer>
            <RedArticleHeader>Instructors:</RedArticleHeader>
            // instructor component
            {/* <Instructor userId={course.instructorId} currentUser={currentUser} /> */}
            <InstructorList instructorIds={course.instructorIds} currentUser={currentUser} />
          </ArticleContainer>

          <RedArticleHeader>Course Title:</RedArticleHeader>
          <WhiteBackgroundContainer>
            <AboutCopyContainer>
              <CollapsedContent
                content={course.title}
                rows={1}
                color={'#333'}
                fontWeight={400}
                fontSize={'0.9375rem'}
                lineHeight={'1.4rem'}
                collapsible={true}
              />
            </AboutCopyContainer>
          </WhiteBackgroundContainer>
        </ArticleContainer>
        <ArticleContainer>
          <RedArticleHeader>Description:</RedArticleHeader>

          <WhiteBackgroundContainer>
            <AboutCopyContainer>
              <CollapsedContent
                content={course.desc}
                rows={5}
                color={'#333'}
                fontWeight={400}
                fontSize={'0.9375rem'}
                lineHeight={'1.4rem'}
                collapsible={true}
              />
            </AboutCopyContainer>
          </WhiteBackgroundContainer>
        </ArticleContainer>

        {/* <ArticleContainer>
          <RedArticleHeader>Instructor:</RedArticleHeader>

          <WhiteBackgroundContainer>
            <AboutCopyContainer>
              <CollapsedContent
                content={`William Ryan`}
                rows={1}
                color={'#333'}
                fontWeight={400}
                fontSize={'0.9375rem'}
                lineHeight={'1.4rem'}
                collapsible={true}
              />
              <CollapsedContent
                content={`william.ryan@uconn.edu`}
                rows={1}
                color={'#333'}
                fontWeight={400}
                fontSize={'0.9375rem'}
                lineHeight={'1.4rem'}
                collapsible={true}
              />
            </AboutCopyContainer>
          </WhiteBackgroundContainer>
        </ArticleContainer> */}

        {/* <ArticleContainer>
          <RedArticleHeader>Resources:</RedArticleHeader>

          <WhiteBackgroundContainer>
            <AboutCopyContainer>
              <CollapsedContent
                content={`Course Syllabus:`}
                rows={1}
                color={'#333'}
                fontWeight={400}
                fontSize={'0.9375rem'}
                lineHeight={'1.4rem'}
                collapsible={true}
              />
              <a
                style={{
                  fontSize: '0.85rem',
                  color: '#333',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}>
                https://catalog.uconn.edu/directory-of-courses/course/MKTG/3101/
              </a>
            </AboutCopyContainer>
          </WhiteBackgroundContainer>
        </ArticleContainer> */}
      </SectionContainer>
    </AsideContainer>
  )
}

export default Details
