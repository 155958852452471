import StartConversation from './components/start-conversation/start-conversation'
import Prompt from './components/prompt'
import { PromptsContainer } from './styled/prompts-comps'

function Prompts({ prompts, setPrompts, course, isInstructor }) {
  console.log('Prompts isInstructor: ', isInstructor)
  return (
    <PromptsContainer>
      <StartConversation
        course={course}
        prompts={prompts}
        setPrompts={setPrompts}
        isInstructor={isInstructor}
      />
      {prompts.length &&
        prompts
          .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
          .map((prompt) => {
            return (
              <Prompt
                key={prompt._id}
                prompt={prompt}
                prompts={prompts}
                setPrompts={setPrompts}
                isInstructor={isInstructor}
              />
            )
          })}
    </PromptsContainer>
  )
}

export default Prompts
