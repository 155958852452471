import CourseMemberCard from '../../course-member-card'
import CourseMemberNotFoundCard from './course-member-not-found-card'
import { styled } from '@mui/material'
import { Box, List, ListSubheader } from '@mui/material'

function SearchResultsList({ users, setUsers, notFound, setNotFound }) {
  const handleRemoveSearchResult = (id) => {
    console.log('Removing:', id)
    const updatedUsers = users.filter((user) => user._id !== id)

    setUsers(updatedUsers)
  }

  const handleRemoveNotFoundSearchResult = (email) => {
    console.log('Removing:', email)
    const updatedNotFound = notFound.filter((notFoundEmail) => notFoundEmail !== email)

    setNotFound(updatedNotFound)
  }

  const profiles = users?.map((profile) => {
    const { _id, avatar, personalAddress, documentVerified, email } = profile

    const verified = documentVerified ? 1 : 0

    return (
      <CourseMemberCard
        id={_id}
        member={profile}
        label="Member"
        isButton={true}
        buttonType={'remove'}
        clickHandler={handleRemoveSearchResult}
      />
    )
  })

  const notFoundProfiles = notFound?.map((email) => {
    return (
      <CourseMemberNotFoundCard
        id={email}
        email={email}
        label={'Not Found'}
        isButton={true}
        buttonType={'remove'}
        clickHandler={handleRemoveNotFoundSearchResult}
      />
    )
  })

  if (!users.length && !notFound.length) return null

  return (
    <MuiList>
      <MuiListSubheader key={'search-subheader-key'}>Search Results</MuiListSubheader>
      {users.length ? profiles : null}
      {notFound.length ? notFoundProfiles : null}
    </MuiList>
  )
}

export default SearchResultsList

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiList = styled(List)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  minWidth: '18rem',
  width: '100%',
  padding: 0,
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.background.gray
}))

const MuiListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.25rem',
  backgroundColor: theme.palette.secondary.light,
  minWidth: '18rem',
  borderRadius: '0.25rem 0.25rem 0 0'
}))
