import { Button } from '@mui/material'

const roleOptions = [
  { label: 'All', value: '' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Instructor', value: 'Instructor' }
]
export const RolesTabs = ({ selected, onChange }) => {
  return (
    <div>
      {roleOptions?.map((option) => (
        <Button
          key={option.value}
          variant="contained"
          color={option.value === selected ? 'primary' : 'secondary'}
          onClick={() => onChange(option.value)}>
          {option.label}
        </Button>
      ))}
    </div>
  )
}
