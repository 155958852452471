import { styled } from '@mui/material'
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'

import { grey, red } from '@mui/material/colors'

function AddSelectedCourseMembers({
  users,
  notFound,
  handleAddUsersToMembers,
  handleAddMembersToInvite,
  handleCloseDialog
}) {
  const hasUsersORNotFound = users.length || notFound.length

  const handleClick = () => {
    handleAddUsersToMembers()
    setTimeout(() => handleCloseDialog(), 1000)
  }

  return hasUsersORNotFound ? (
    <MuiBox>
      <Typography
        variant="h5"
        sx={{
          backgroundColor: 'secondary.light',
          p: '0.5rem 1rem',
          borderRadius: '0.25rem',
          width: '16rem',
          fontSize: '1.25rem',
          fontWeight: 500
        }}>
        Search Results Summary:
      </Typography>
      <Box
        sx={{
          backgroundColor: grey[200],
          borderRadius: '0.25rem',
          padding: '1rem',
          margin: '0.5rem 0 1rem 0'
        }}>
        <List sx={{ padding: 0 }}>
          <ListItem sx={{ padding: '0.5rem 0' }}>
            <ListItemIcon sx={{ minWidth: '40px' }}>
              <Badge
                badgeContent={users.length}
                color="primary"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}>
                <CheckCircleIcon sx={{ fontSize: '2.5rem', fill: 'secondary.main' }} />
              </Badge>
            </ListItemIcon>
            <MuiListItemText
              secondary="Click Add/Invite below to automatically add them to the course"
              sx={{ ml: 2 }}>
              <Typography variant="h6">
                <Box
                  sx={{
                    display: 'inline',
                    color: 'secondary.main',
                    fontWeight: 700
                  }}>
                  {users.length}
                </Box>{' '}
                emails associated with existing CivilTalk members
              </Typography>
            </MuiListItemText>
          </ListItem>
          <ListItem sx={{ padding: '0.5rem 0' }}>
            <ListItemIcon sx={{ minWidth: '40px' }}>
              <Badge
                badgeContent={notFound.length}
                color="primary"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}>
                <CancelOutlinedIcon sx={{ fontSize: '2.5rem', fill: red[200] }} />
              </Badge>
            </ListItemIcon>
            <MuiListItemText
              secondary="Click Add/Invite below to invite them to join CivilTalk and get added to the course"
              sx={{ ml: 2 }}>
              <Typography variant="h6">
                <Box
                  sx={{
                    display: 'inline',
                    color: 'warning.main',
                    fontWeight: 700
                  }}>
                  {notFound.length}
                </Box>{' '}
                emails not yet associated with CivilTalk members
              </Typography>
            </MuiListItemText>
          </ListItem>
        </List>
      </Box>
      <Button
        variant="contained"
        sx={{ fontSize: '1.25rem', fontWeight: 500 }}
        startIcon={<AddCircleOutlinedIcon />}
        onClick={handleClick}>
        Add {users.length} / Invite {notFound.length} Course Members
      </Button>
    </MuiBox>
  ) : null
}

export default AddSelectedCourseMembers

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // gap: '1rem',
  margin: '1rem 0',
  // padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff'
  // border: `1px solid ${theme.palette.secondary.main}`
}))

const MuiListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: theme.palette.text.primary,
    fontSize: '1.15rem',
    marginBottom: '0.25rem'
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.text.primary,
    fontSize: '0.95rem'
  }
}))
