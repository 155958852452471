import { useState, useCallback, useRef, useEffect } from 'react'
import { styled } from '@mui/material'
import { Box, Typography, Button } from '@mui/material'
import AddCourseMembersDialog from './components/add-course-members-dialog'
import CourseMembersList from './components/course-members-list'
import CourseStepButtonContainer from '../course-step-button-container'
import AddIcon from '@mui/icons-material/Add'

function CourseMembersPanel({
  courseFields,
  handleSetCourseFields,
  handlePreviousStep,
  handleNextStep,
  activeStep,
  steps,
  setStepUpdated
}) {
  const [users, setUsers] = useState([])
  const [notFound, setNotFound] = useState([])
  const [members, setMembers] = useState([])
  const [open, setOpen] = useState(false)
  const [formErrors, setFormErrors] = useState(false)

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const handleAddUsersToMembers = () => {
    setStepUpdated(true)

    console.log('users', users)
    console.log('members', members)

    // Create a Set of existing member IDs
    const existingMemberIds = new Set(courseFields.members.map((member) => member.userId))

    console.log('existingMemberIds', existingMemberIds)

    // Filter out duplicates and combine arrays
    const updatedMembers = [
      ...courseFields.members,
      ...users.filter((user) => !existingMemberIds.has(user.userId))
    ]

    // const updatedMembers = [...courseFields.members, ...users]

    setMembers(updatedMembers)

    handleSetCourseFields({ ...courseFields, members: updatedMembers, membersToInvite: notFound })

    // clear the search results
    setUsers([])
  }

  const handleAddMembersToInvite = () => {
    setStepUpdated(true)

    // add notFound to CourseFields.membersToInvite
    const updatedMembersToInvite = [...courseFields.membersToInvite, ...notFound]

    handleSetCourseFields({ ...courseFields, membersToInvite: updatedMembersToInvite })
  }

  const handleRemoveMember = (id) => {
    setStepUpdated(true)
    const updatedMembers = members.filter((member) => member._id !== id)

    setMembers(updatedMembers)
    handleSetCourseFields({ ...courseFields, members: updatedMembers })
  }

  useEffect(() => {
    setMembers(courseFields.members)
  }, [])

  return (
    <MuiBoxOuterContainer>
      <MuiBoxButtonContainer>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
          Add Members
        </Button>
        <AddCourseMembersDialog
          open={open}
          handleCloseDialog={handleCloseDialog}
          users={users}
          setUsers={setUsers}
          notFound={notFound}
          setNotFound={setNotFound}
          handleAddUsersToMembers={handleAddUsersToMembers}
          handleAddMembersToInvite={handleAddMembersToInvite}
        />
      </MuiBoxButtonContainer>
      <CourseMembersList
        members={members}
        handleRemoveMember={handleRemoveMember}
        notFound={notFound}
        setNotFound={setNotFound}
      />
      <CourseStepButtonContainer
        handlePreviousStep={handlePreviousStep}
        handleNextStep={handleNextStep}
        activeStep={activeStep}
        steps={steps}
        formErrors={formErrors}
        editingCourse={courseFields.status !== 'pending'}
      />
    </MuiBoxOuterContainer>
  )
}

export default CourseMembersPanel

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBoxOuterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  margin: '1rem 0',
  padding: '2rem',
  borderRadius: '0.25rem',
  background: '#fff'
}))

const MuiBoxButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start'
}))
