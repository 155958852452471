import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled } from '@mui/material'

function CourseStepButtonContainer({
  handlePreviousStep,
  handleNextStep,
  activeStep,
  steps,
  formErrors,
  editingCourse
}) {
  const finalButtonCopy = editingCourse ? 'Update Course' : 'Publish Course'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
      }}>
      <MuiBackButton
        sx={{ width: '9rem' }}
        variant="contained"
        onClick={handlePreviousStep}
        disabled={activeStep === 0}>
        Back
      </MuiBackButton>
      <Button
        sx={{ width: '9rem' }}
        variant="contained"
        disabled={formErrors}
        onClick={handleNextStep}>
        {activeStep === steps.length - 1 ? finalButtonCopy : 'Next'}
      </Button>
    </Box>
  )
}

export default CourseStepButtonContainer

// ***************************** //
// *** MUI Styled Components *** //
// ***************************** //

const MuiBackButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  '&:hover': {
    backgroundColor: theme.palette.grey[500]
  }
}))
