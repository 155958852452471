import * as Sentry from '@sentry/react'
import config from '../../../config'
import { actionResponseActions } from '../../action-creators/action-response'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const updateCourseSetupStepByCourseID = (params) => {
  console.log('[DEBUG]: updateCourse params', params)

  let responseCode = ''
  const method = 'PATCH'
  const url = `${config.baseURL}/api/course/${params.courseId}/${params.step}`
  const headers = {
    'Content-Type': 'application/json'
  }
  const requestBody = JSON.stringify(params.fieldsToUpdate)

  return (dispatch) => {
    // dispatch(loaderActions.startLoader())
    console.log('[FETCH]: Sending update course setup step by ID request to CivilTalk')

    return authenticatedFetch(url, {
      method,
      body: requestBody,
      headers,
      credentials: 'include'
    })
      .then((response) => {
        // dispatch(loaderActions.stopLoader())

        responseCode = response.status

        return response.json()
      })
      .then((data) => {
        if (responseCode === 200) {
          console.log(`[SUCCESS]:`, data.message)
          dispatch(
            actionResponseActions.createActionResponse(
              `Successfully updated course setup step: ${params.step + 1}`
            )
          )

          return {
            error: false,
            code: responseCode,
            message: data.message,
            updatedFields: data.updatedFields
          }
        } else {
          console.error(
            `[ERROR]: while updating courseId: ${params.courseId} at step: ${params.step}`,
            data
          )

          // Log error to Sentry
          Sentry.captureException(data)

          return {
            error: true,
            code: responseCode,
            message: data.message
          }
        }
      })
      .catch((error) => {
        console.error(
          `[ERROR]: caught while updating courseId: ${params.courseId} at step: ${params.step}`,
          data
        )

        // Log error to Sentry
        Sentry.captureException(error)

        return {
          error: true,
          code: 500,
          message: 'Failed to update course setup step'
        }
      })
  }
}
