import config from '../../../config'
import { authenticatedFetch } from '../../../utils/functions/authenticatedFetch'

export const searchUsersByEmails = (params) => {
  console.log('Params:', params)
  return async (dispatch) => {
    let responseCode = ''
    const method = 'GET'
    const emails = JSON.stringify(params.emails)

    const url = `${config.baseURL}/api/search/usersByEmails?emails=${emails}`

    const headers = {
      'Content-Type': 'application/json'
    }

    try {
      const response = await authenticatedFetch(url, {
        method,
        headers,
        credentials: 'include'
      })

      responseCode = response.status
      const data = await response.json()

      if (responseCode === 200) {
        console.log(`[SUCCESS]:`, data.message)
        return {
          error: false,
          code: responseCode,
          message: data.message,
          profiles: data.profiles,
          usersNotFound: data.usersNotFound
        }
      } else {
        return {
          error: true,
          code: responseCode,
          message: data.message
        }
      }
    } catch (error) {
      console.error('[ERROR]: ', error.message)
      return {
        error: true,
        code: 500,
        message: 'Internal Server Error'
      }
    }
  }
}
