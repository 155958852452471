import { Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { MuiCheckbox } from '../styled/mui-styled'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Label } from '../styled/organization-admin-comps'

const editableRoles = ['Instructor']

const EditRoleModal = ({ userInfo, open, onCancel, onSave }) => {
  const fullName = `${userInfo?.firstName} ${userInfo?.lastName}`

  const [selectedRoles, setSelectedRoles] = useState([])

  const saveHandler = () => onSave(selectedRoles?.filter((role) => !readOnlyRoles?.includes(role)))

  const onSelectRoles = (event, value) => {
    setSelectedRoles((prev) => {
      if (value) return [...(prev || []), event?.target?.id]
      return prev?.filter((item) => item !== event?.target?.id)
    })
  }

  useEffect(() => {
    userInfo?.roles && setSelectedRoles(userInfo?.roles)

    return () => {
      setSelectedRoles([])
    }
  }, [userInfo?.roles])

  const readOnlyRoles = useMemo(() => {
    return userInfo?.roles?.filter((item) => !editableRoles.includes(item))
  }, [userInfo?.roles])

  return (
    <Dialog maxWidth="lg" open={open} onClose={onCancel} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        Modifing Role of {fullName} - {userInfo?.email}
      </DialogTitle>

      <DialogContent>
        {readOnlyRoles?.map((item) => (
          <div key={item}>
            <DialogContentText>
              <MuiCheckbox id={item} color="primary" checked readOnly disableRipple />
              <Label htmlFor={item}>{item}</Label>
            </DialogContentText>
          </div>
        ))}

        {editableRoles?.map((option) => (
          <div key={option}>
            <DialogContentText>
              <MuiCheckbox
                id={option}
                color="secondary"
                checked={selectedRoles?.includes(option)}
                onChange={onSelectRoles}
              />
              <Label htmlFor={option}>{option}</Label>
            </DialogContentText>
          </div>
        ))}
      </DialogContent>

      <DialogActions style={{ paddingBottom: '1rem' }}>
        <Button onClick={onCancel} color="secondary" variant="outlined">
          Cancel
        </Button>

        <Button onClick={saveHandler} color="secondary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditRoleModal
